import { useContext, useEffect } from "react";
import { getProvider } from "../../services/ethereum/contract/web3";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import { JoinAffiliateContext } from "../contexts/JoinAffiliateContext";

const useOnchangeEvents = () => {
  //constructor
  const { connector, isWalletConnected, setParticipant, participant, setIsConnecting, isConnecting } = useContext(JoinAffiliateContext);

  //constants

  useEffect(() => {
    const start = async () => {
      const provider = await getProvider(connector);

      if (provider === null) return;
      provider.on("accountsChanged", (accounts: string[]) => {
        if (isWalletConnected && accounts.length) setParticipant({ ...participant, participantAddress: accounts[0] });
      });
      provider.on("chainChanged", (networkId: string) => {
        if (networkId === "0x38") lottoContract.setup();
        else {
          setIsConnecting(false);
        }
      });
    };
    start();
  }, [connector]);
};

export default useOnchangeEvents;
