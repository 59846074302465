import { FC, useEffect } from "react";
import { GlobalStyles } from "@mui/material";
import S from "./layout.styled";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import useOnchangeEvents from "../../utils/hooks/events";
import ReferAndYearn from "../refer-and-yearn";
import Content from "./content";

const Layout: FC = () => {
  //To listen to wallet events.
  useOnchangeEvents();

  //setup contract
  useEffect(() => {
    lottoContract.setup();
  }, []);

  return (
    <S.Layout>
      <GlobalStyles
        styles={(theme) => ({
          "*": {
            "&::-webkit-scrollbar": {
              width: "5px",
              height: "5px",
              backgroundColor: "transparent",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              backgroundColor: theme.palette.custom.primary.main,
              height: "20px",
            },
          },
        })}
      />
      <Content>
        <ReferAndYearn />
      </Content>
    </S.Layout>
  );
};

export default Layout;
