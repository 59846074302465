import { FC } from "react";
import { CircularProgress } from "@mui/material";
import { S } from "./loader.styled";

const ButtonLoader: FC = () => {
  return (
    <S.ButtonLoader>
      <CircularProgress size={20} />
    </S.ButtonLoader>
  );
};

export default ButtonLoader;
