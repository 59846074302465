import { Promise } from "bluebird";
import { getCurrentChainId } from "../../../utils/helpers";
import Toast from "../../../utils/widgets/toast";
import ABI from "../build/abi.json";
import { CONTRACT, web3 } from "./web3";


export namespace lottoContract {
  let deployed, web3Contract: any;

  export const setup = async () => {
    deployed = false;
    let currentChainId = Number(process.env.REACT_APP_CURRENT_CHAINID);
    console.log("setup", currentChainId);
    const myContractAbi = ABI.abi as any; // ABI definitions
    web3Contract = new web3.eth.Contract(myContractAbi, CONTRACT[currentChainId ?? 56]);
    Promise.promisifyAll(web3Contract, { suffix: "Promise" });
    deployed = true;
    return deployed;
  };

  export const participantByAddress = async (address: string) => {
    if (address === "") return;
    let participant = await web3Contract.methods.participants(address).call();
    return participant;
  };

  export const participantById = async (id: string) => {
    let participant = await web3Contract.methods.participantsById(id).call();
    console.log(participant, "participantById");
    return participant;
  };

  export const joinFreeReferrer = async (address: string, handleConnecting: (isSuccess: boolean) => void, cb?: (res: any) => void) => {
    web3Contract.methods
      .joinAsAffiliate()
      .send({ from: address, gaslimit: 1100000 })
      .on("transactionHash", (hash: any) => {
        console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        console.log(receipt, "receipt");
        handleConnecting(true);
      })
      .on("error", (error: any, receipt: any) => {
        console.log("error", error.message, receipt);
        Toast({ message: error.message, type: "error" });
        handleConnecting(false);
      });
  };
}