import { Dispatch, SetStateAction } from "react";
import { getProvider, web3 } from "../services/ethereum/contract/web3";
import { lottoContract } from "../services/ethereum/contract/web3Contract";
import { METAMASK, NULL_ADDRESS, WALLET_CONNECT } from "../utils/constant";
import { getWalletBalance } from "../utils/helpers";
import { Participant } from "../utils/types/common";
import Toast from "../utils/widgets/toast";


export const connectWallet = async (connector: string, setIsConnecting: Dispatch<SetStateAction<boolean>>, cb: (address: string) => void) => {
  let provider = await getProvider(connector);
  if (provider !== null) {
    provider
      .enable()
      .then(async (res: any) => {
        await lottoContract.setup();
        if (process.env.REACT_APP_CURRENT_CHAINID !== "80001") {
          if (
            (provider !== null && connector === WALLET_CONNECT && provider.chainId !== "0x38") ||
            (provider !== null && connector === METAMASK && provider.chainId !== "0x38")
          ) {
            Toast({ message: "Kindly switch the network to BSC Smart Chain." });
            setIsConnecting(false);
            return;
          }
        }
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          cb(accounts[0]);
        } else {
          Toast({ message: "Kindly add an account to continue!" });
          setIsConnecting(false);
        }
      })
      .catch((e: any) => {
        console.log(e);
        setIsConnecting(false);
      });
    provider.on("accountsChanged", async (accounts: any) => {
      window.location.reload();
    });
    provider.on("chainChanged", async (chainId: any) => {
      window.location.reload();
    });
    provider.on("disconnect", async (error: any) => {
      window.location.reload();
    });
  } else {
    Toast({ message: "Wallet not installed!", type: "error" });
    setIsConnecting(false);
  }
};

export const verifyParticipant = async (address: string) => {
  let verified = false;
  var participantId;

  if (address !== "" && web3.utils.isAddress(address)) {
    let participant = await lottoContract.participantByAddress(address);
    if (participant.addr === NULL_ADDRESS && participant.amount === "0" && !participant.is_free_user) verified = true;
    participantId = participant[0];
  }

  return {isVerified : verified, participantId: participantId}
};

export const joinAffiliate = async (
  connector: string,
  setIsConnecting: Dispatch<SetStateAction<boolean>>,
  setPage?: Dispatch<SetStateAction<number>>,
  setParticipant?: Dispatch<SetStateAction<Participant>>,
  cb?: () => void,
) => {
  let provider = await getProvider(connector);
  if (provider !== null) {
    web3.setProvider(provider as any);

    provider
      .enable()
      .then(async (res: any) => {
        await lottoContract.setup();
        if (process.env.REACT_APP_CURRENT_CHAINID !== "80001") {
          if (
            (provider !== null && connector === WALLET_CONNECT && provider.chainId !== "0x38") ||
            (provider !== null && connector === METAMASK && provider.chainId !== "0x38")
          ) {
            Toast({ message: "Kindly switch the network to BSC Smart Chain." });
            setIsConnecting(false);
            return;
          }
        }
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          let participant = await lottoContract.participantByAddress(accounts[0]);

          if (participant.addr === NULL_ADDRESS && participant.amount === "0" && !participant.is_free_user) {
            let walletBal = await getWalletBalance(accounts[0]);
            if (+walletBal > 0.001)
              await lottoContract.joinFreeReferrer(accounts[0], (isSuccess) => {
                if (isSuccess && cb) cb();
                setIsConnecting(false);
              });
            else {
              Toast({ message: "Insufficient gas amount. Kindly recharge your account!", type: "error" });
              setIsConnecting(false);
            }
          } else {
            Toast({ message: "The account is already an affiliate!" });
            setPage && setPage(2);
            setParticipant && setParticipant({ ...participant, participantAddress: accounts[0], participantId: participant[0] });
            setIsConnecting(false);
          }
        } else {
          Toast({ message: "Kindly add an account to continue!" });
          setIsConnecting(false);
        }
      })
      .catch((e: any) => {
        console.log(e);
        setIsConnecting(false);
      });
  } else {
    Toast({ message: "Wallet not installed!", type: "error" });
    setIsConnecting(false);
  }
};