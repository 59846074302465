import { FC, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Checkbox } from "@mui/material";
import S from "../affiliate-modal.styled";
import { registerAffiliateUser } from "../../../services/api";
import { JoinAffiliateContext } from "../../../utils/contexts/JoinAffiliateContext";
import { validatePhoneNumber } from "../../../utils/helpers";
import { Button } from "../../../utils/widgets/buttons";
import ButtonLoader from "../../../utils/widgets/loader/ButtonLoader";
import Toast from "../../../utils/widgets/toast";
import PhoneNumberInput from "../../phone-input/PhoneNumberInput";

const UserInfoPage: FC = () => {
  //state values
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    youtubeLink: "",
    instagramLink: "",
    twitterLink: "",
    telegramLink: "",
  });
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [enableRegister, setEnableRegister] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [mobile, setMobile] = useState("");
  const { participant, connector, setIsConnecting, setPage, setParticipant, isConnecting } = useContext(JoinAffiliateContext);

  const onInputChange = (e: any) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (userInfo.name.trim().length > 0 && userInfo.email.trim().length > 0 && mobile.trim().length > 0 && agreeTerms && !phoneError && !mailError) {
      setEnableRegister(true);
    } else setEnableRegister(false);
  }, [userInfo, agreeTerms, mobile, phoneError, mailError]);

  return (
    <S.UserInfoWrapper>
      <S.PersonalConainer>
        <S.InformationConainer>
          <S.CustomTextField
            id="outlined-basic"
            value={userInfo.name}
            onChange={(e: any) => onInputChange(e)}
            required={true}
            type="text"
            label="Name"
            variant="outlined"
            name="name"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
          />
          <S.CustomTextField
            id="outlined-basic"
            value={userInfo.email}
            onChange={(e: any) => onInputChange(e)}
            required={true}
            type="email"
            name="email"
            label="Email"
            variant="outlined"
            error={mailError}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            onBlur={() => {
              if (userInfo.email.trim().length) {
                let validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (!userInfo.email.match(validRegex)) {
                  setMailError(true);
                  Toast({ message: "Enter a valid email id!", type: "error" });
                }
              }
            }}
            onFocus={() => {
              if (mailError) setMailError(false);
            }}
          />
          <PhoneNumberInput
            setPhoneNumber={setMobile}
            error={phoneError}
            onBlur={() => {
              if (mobile.trim().length) {
                let verified = validatePhoneNumber(mobile);
                if (!verified) {
                  setPhoneError(true);
                  Toast({ message: "Enter a valid phone number!", type: "error" });
                }
              }
            }}
            onFocus={() => {
              if (phoneError) setPhoneError(false);
            }}
            phoneNumber={mobile}
          />
          <S.CustomTextField
            id="outlined-basic"
            value={userInfo.youtubeLink}
            onChange={(e: any) => onInputChange(e)}
            type="url"
            name="youtubeLink"
            label="Youtube Profile"
            variant="outlined"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
          />
          <S.CustomTextField
            id="outlined-basic"
            value={userInfo.instagramLink}
            onChange={(e: any) => onInputChange(e)}
            type="url"
            name="instagramLink"
            label="instagram"
            variant="outlined"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
          />
          <S.CustomTextField
            id="outlined-basic"
            value={userInfo.twitterLink}
            onChange={(e: any) => onInputChange(e)}
            type="url"
            name="twitterLink"
            label="Twitter Profile"
            variant="outlined"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
          />
          <S.CustomTextField
            id="outlined-basic"
            value={userInfo.telegramLink}
            onChange={(e: any) => onInputChange(e)}
            type="url"
            name="telegramLink"
            label="Telegram"
            variant="outlined"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
          />
        </S.InformationConainer>
      </S.PersonalConainer>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <S.CustomFormGroup>
          <S.CheckBoxLabel
            control={<Checkbox style={{ color: "#0AAD2E" }} checked={agreeTerms} />}
            label={
              <p>
                I certify that I am at least 18 years old and that I agree to the{" "}
                <a
                  href="https://docs.yearntogether.com/terms-and-conditions/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#4b68f2",
                  }}
                >
                  terms and conditions
                </a>{" "}
                of YearnTogether{" "}
              </p>
            }
            onChange={() => setAgreeTerms((prevState) => !prevState)}
          />
        </S.CustomFormGroup>
        <Button
          onClick={() => {
            if (enableRegister) {
              setIsConnecting(true);
              registerAffiliateUser({ ...userInfo, mobile: `+${mobile}` }, participant, connector, setIsConnecting, setPage, setParticipant);
            }
          }}
          disabled={!enableRegister}
          customStyle={{ fontSize: "14px", width: "112px", height: "32px" }}
        >
          {isConnecting ? <ButtonLoader /> : "Register"}
        </Button>
      </Box>
    </S.UserInfoWrapper>
  );
};

export default UserInfoPage;
