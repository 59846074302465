import React, { FC } from "react";
import S from "./content.styled";

type Props = {
  children: React.ReactNode;
};

const Content: FC<Props> = ({ children }) => {
  return <S.ContentContainer>{children}</S.ContentContainer>;
};

export default Content;
