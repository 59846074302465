import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EastIcon from "@mui/icons-material/East";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { styled, Box, Typography, keyframes, Button } from "@mui/material";

namespace S {
  export const MainWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    position: "relative",
    background: "linear-gradient(180deg, #050016 0%, #16151A 100%);",
  }));

  export const BlurredImgWrapper = styled(Box)(({ theme }) => ({
    position: "relative",
    overflow: "hidden",
  }));

  export const BackgroundGrillImg = styled("img")(({ theme }) => ({
    width: "100%",
    height: "50%",
    position: "absolute",
    top: "-2rem",
    left: 0,
  }));

  const BlurAnime1 = keyframes`
  0% {
    top: -80vh;
    transform:scale(0.5);
  }
  100% {
    top:-50vh;
    transform:scale(0.5);
  }
`;

  export const BlurredImg1 = styled("img")(({ theme }) => ({
    position: "absolute",
    left: "-42rem",
    top: "-80vh",
    animation: `${BlurAnime1} 3s alternate ease-in-out infinite`,
  }));

  const BlurAnime2 = keyframes`
  0% {
    top:0%;
    transform:scale(1);
  }
  100% {
    top: -10%;
    transform:scale(1.5);
  }
`;
  export const BlurredImg2 = styled("img")(({ theme }) => ({
    position: "absolute",
    right: "-18%",
    top: "0",
    height: "1000px !important",
    width: "1000px !important",
    animation: `${BlurAnime2} 4s 1s alternate ease-in-out infinite`,
  }));

  export const BlurredImg3 = styled("img")(({ theme }) => ({
    position: "absolute",
    right: "20%",
    bottom: "-20%",
    height: "1100px !important",
    width: "1100px !important",
    [theme.breakpoints.up("xl")]: {
      right: "25%",
    },
    [theme.breakpoints.down("xl")]: {
      right: "12%",
    },
    [theme.breakpoints.down("lg")]: {
      right: "-0%",
    },
    [theme.breakpoints.down("md")]: {
      right: "-10%",
    },
    [theme.breakpoints.down("sm")]: {
      right: "-50%",
      display: "none",
    },
  }));

  const YoutubeAnime = keyframes`
  0% {
    left:-5%;
    top:30%;
    transform: scale(0.8);
  }
  25% {
    top:20%;
    transform:scale(1);
  }
  50% {
    top:20%;
    transform:scale(1);
  }
  65%{
    top:30%;
    left:0%;
    transform:scale(0.8)
  }
  85% {
    top:30%;
    left:0%;
    transform:scale(0.8)
  }
  90% {
    top:30%;
    transform:scale(0.8);
  }
  100% {
    top:30%;
    transform:scale(0.8);
  }
`;

  export const YoutubeIcon = styled("img")(({ theme }) => ({
    position: "absolute",
    left: "-5%",
    top: "30%",
    transform: "translate(0% 0%)",
    animation: `${YoutubeAnime} 6s ease-in-out infinite`,
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      height: "auto",
      transform: "scale(0.5)",
    },
  }));

  const InstaAnime = keyframes`
  0% {
    left:5%;
    top:80%;
    transform: scale(0.8);
  }
  25% {
    top:70%;
    transform:scale(1);
  }
  50%{
    top:70%;
    transform:scale(1);
  }
  65%{
    top:80%;
    left:0%;
    transform:scale(0.8)
  }
  85% {
    top:80%;
    left:0%;
    transform:scale(0.8)
  }
  90% {
    top:80%;
    transform:scale(0.8);
  }
  100% {
    top:80%;
    transform:scale(0.8);
  }
`;

  export const InstagramIcon = styled("img")(({ theme }) => ({
    position: "absolute",
    left: "5%",
    top: "80%",
    animation: `${InstaAnime} 5s 1s ease-in-out infinite`,
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      transform: "scale(0.5)",
    },
  }));

  const TwitterAnime = keyframes`
  0% {
    right: "-5%";
    top: "28%";
    transform: scale(0.5);
  }
  25% {
    top:18%;
    transform:scale(1);
  }
  50% {
    top:18%;
    transform:scale(1);
  }
  65%{
    top:28%;
    right:0%;
    transform:scale(0.8)
  }
  85%{
    top:28%;
    right:0%;
    transform:scale(0.8)
  }
  90% {
    top:28%;
    transform:scale(0.8);
  }
  100% {
    top:28%;
    transform:scale(0.8);
  }
`;

  export const TwitterIcon = styled("img")(({ theme }) => ({
    position: "absolute",
    right: "-5%",
    top: "28%",
    animation: `${TwitterAnime} 6s alternate ease-in-out infinite`,
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      transform: "scale(0.5)",
    },
  }));

  const FaceBookAnime = keyframes`
  0% {
    right: "5%";
    top: "80%";
    transform: scale(0.8);
  }
  25% {
    top:70%;
    transform:scale(1);
  }
  50% {
    top:70%;
    transform:scale(1);
  }
  65%{
    top:80%;
    right:0%;
    transform:scale(0.8)
  }
  85%{
    top:80%;
    right:0%;
    transform:scale(0.8)
  }
  90% {
    top:80%;
    transform:scale(0.8);
  }
  100% {
    top:80%;
    transform:scale(0.8);
  }
`;

  export const FacebookIcon = styled("img")(({ theme }) => ({
    position: "absolute",
    right: "5%",
    top: "80%",
    animation: `${FaceBookAnime} 6s 1s ease-in-out infinite`,
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      transform: "scale(0.5)",
    },
  }));

  export const ReferAndYearnContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    margin: "auto",
    width: "100%",
    maxWidth: "1380px",
    padding: "0 20px",
    minHeight: "100vh",
    color: theme.palette.custom.text.white,
    [theme.breakpoints.down("sm")]: {
      padding: "0 2%",
    },
  }));

  export const HowItWorksContainer = styled(Box)(({ theme }) => ({
    minHeight: "75vh",
    position: "relative",
  }));

  export const HowItWorksHeader = styled(Typography)(({ theme }) => ({
    fontSize: "3.5rem",
    fontWeight: 700,
    textAlign: "center",
    color: theme.palette.custom.text.white,
    [theme.breakpoints.down("sm")]: {
      fontSize: "clamp(20px,24px,28px)",
    },
  }));

  export const HowItWorksSubHeader = styled(Typography)(({ theme }) => ({
    fontSize: "1.125rem",
    fontWeight: 400,
    textAlign: "center",
    opacity: 0.9,
    color: theme.palette.custom.text.white,
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      width: "95%",
      fontSize: "clamp(14px,16px,18px)",
    },
  }));

  export const CardsContainer = styled(Box)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
    gridGap: "1.875rem",
    maxWidth: "1380px",
    margin: "auto",
    padding: "5.625rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "5.625rem 1rem",
    },
    [theme.breakpoints.down(480)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  }));

  export const Card = styled(Box)(({ theme }) => ({
    height: "438px",
    background: "#24222E",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "40px 96px 90px 40px",
    color: theme.palette.custom.text.white,
    zIndex: "2",
    "&:hover": {
      background: "#302084",
      cursor: "pointer",
      transform: "scale3d(1.05, 1.05, 1)",
    },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "30px 30px 60px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "20px 20px 45px 20px",
    },
  }));

  export const HowItWorksImg = styled("img")(({ theme }) => ({
    width: "100px",
    height: "100px",

    [theme.breakpoints.down("md")]: {
      width: "75px",
      height: "75px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "50px",
    },
  }));

  export const HowItWorksTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  export const HowItWorksContent = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: "1.125rem",
    opacity: "0.8",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  }));

  export const ReferAndYearnTopContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    padding: "120px 0",
    minWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      padding: "1rem 0",
    },
  }));

  export const AffiliateProgram = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      gap: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "1rem",
      height: "90vh",
      justifyContent: "center",
    },
  }));

  export const Caption = styled(Typography)(({ theme }) => ({
    fontWeight: "500",
    color: theme.palette.custom.text.black,
  }));

  export const CustomEastIcon = styled(EastIcon)(({ theme }) => ({
    fontWeight: "600",
    color: theme.palette.custom.text.black,
    fontSize: "1.2rem",
    alignSelf: "center",
  }));

  export const HeaderText = styled(Typography)(({ theme }) => ({
    fontWeight: "600",
    lineHeight: "120%",
    textAlign: "center",
    opacity: 0.9,
    "&.main-title": {
      fontSize: "5rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "clamp(30px,38px,40px) !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "clamp(16px,20px,22px)!important",
      },
    },
    "&.sub-title": {
      fontSize: "2.5rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "clamp(20px,24px,28px) !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "clamp(10px,12px,14px) !important",
      },
    },
  }));

  export const HighlightText = styled("span")(({ theme }) => ({
    fontWeight: "600",
    color: theme.palette.custom.text.highlight,
    "&.yellow-text": {
      color: theme.palette.custom.button.yellow,
    },
  }));

  export const SubText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    lineHeight: "31.05px",
    fontWeight: "400",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      width: "95%",
      fontSize: "clamp(14px,16px,18px)",
    },
  }));

  export const CustomChevronRightIcon = styled(ChevronRightIcon)(() => ({
    height: "20px",
  }));

  const BtnAnime = keyframes`
  0% {
    transform:rotate(0deg);
  }
  20% {
    transform:rotate(5deg);
  }
  50% {
    transform:rotate(5deg);
  }
  70%{
    transform:rotate(-5deg);
  }
  100%{
    transform:rotate(-5deg);
  }
`;

  export const ButtonContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "40px",
    alignItems: "center",
    justifyContent: "center",
    "button:first-of-type": {
      postion: "relative",
      animation: `${BtnAnime} alternate 3s 1.5s ease-in-out infinite`,
      [theme.breakpoints.down("sm")]: {
        fontSize: "clamp(14px,16px,18px)",
        padding: "16px 20px",
        // animation: "none",
      },
      "&:before": {
        content: '""',
        width: "20px",
        height: "20px",
        top: "50%",
        left: 0,
        transform: "translate(-40%,-50%)",
        borderRadius: "50%",
        background: "#110c20",
        position: "absolute",
        [theme.breakpoints.down("sm")]: {
          background: "#231a37",
        },
      },
      "&:after": {
        content: '""',
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        background: "#110c20",
        top: "50%",
        right: 0,
        transform: "translate(40%,-50%)",
        position: "absolute",
        [theme.breakpoints.down("sm")]: {
          background: "#231a37",
        },
      },
    },
  }));

  export const PlayButton = styled(Button)(({ theme }) => ({
    width: "56px !important",
    height: "62px !important",
    borderRadius: "50%",
    background: "#2f84f8",
    padding: "16px",
    animation: "none !important",
    "&:hover": {
      background: "#2f84f8",
    },
    "&:before": {
      background: "transparent !important",
    },
    "&:after": {
      background: "transparent !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  }));

  export const ButtonImage = styled("img")(({ theme }) => ({
    width: "20px",
    height:"20px",
    animation: "none",
  }));

  export const CustomPlayCircleIcon = styled(PlayCircleFilledIcon)(({ theme }) => ({
    color: theme.palette.custom.primary.main,
    fontSize: "4.3rem",
  }));

  export const ReferAndYearnPoster = styled("img")(() => ({
    width: "100%",
    height: "100%",
  }));

  export const ReferAndYearnBottomContainer = styled(Box)(() => ({
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    padding: "60px 0 100px 0",
  }));

  export const RegisterContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    maxWidth: "286px",
  }));

  export const Heading = styled(Typography)(({ theme }) => ({
    fontSize: "22px",
    fontWeight: "600",
  }));

  export const Coin = styled("span")(({ theme }) => ({
    height: "24px",
    width: "24px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.8rem",
    fontWeight: "600",
    color: theme.palette.custom.text.white,
    backgroundColor: theme.palette.custom.button.yellow,
  }));

  export const RegisterSubText = styled(SubText)(() => ({
    textAlign: "center",
    letterSpacing: "0em",
  }));

  export const ContentIcon = styled("img")(() => ({}));

  export const Seperator = styled("span")(({ theme }) => ({
    width: "2px",
    height: "100px",
    backgroundColor: theme.palette.custom.strokes.secondary,
    alignSelf: "flex-end",
  }));
}

export default S;
