import { FC, useContext, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import S from "../affiliate-modal.styled";
import WalletConnect from "../../../assets/images/Walletconnect.png";
import metamask from "../../../assets/images/metamask.svg";
import { connectWallet, verifyParticipant } from "../../../actions";
import { METAMASK, WALLET_CONNECT } from "../../../utils/constant";
import { JoinAffiliateContext } from "../../../utils/contexts/JoinAffiliateContext";
import { getEllipsisString } from "../../../utils/helpers";
import { Button } from "../../../utils/widgets/buttons";
import ButtonLoader from "../../../utils/widgets/loader/ButtonLoader";
import Toast from "../../../utils/widgets/toast";


const ConnectPage: FC = () => {
  //state values
  const [currentPage, setCurrentPage] = useState(1);
  const { isConnecting, setIsConnecting, setConnector, setIsWalletConnected, participant, setParticipant, isWalletConnected, setPage } =
    useContext(JoinAffiliateContext);

  //function
  const handleWalletConnect = (connector: string) => {
    if (!isConnecting) {
      setIsConnecting(true);
      connectWallet(connector, setIsConnecting, (res: string) => {
        onWalletConnected(res, connector);
      });
    }
  };

  const onWalletConnected = async (address: string, connector: string) => {
    const { isVerified, participantId } = await verifyParticipant(address);
    if (isVerified) {
      Toast({ message: "Wallet connect successfully!", type: "success" });
      setParticipant({ ...participant, participantAddress: address });
      setConnector(connector);
      setIsWalletConnected(true);
      setIsConnecting(false);
    } else {
      setPage(2);
      setParticipant({ ...participant, participantAddress: address, participantId: participantId });
      setIsConnecting(false);
      Toast({ message: "This account already exists!", type: "error" });
    }
  };

  return (
    <S.ConnectPageWrapper>
      <S.BodyTitle>Connect {currentPage === 1 ? "Metamask" : "Walletconnect"}</S.BodyTitle>
      <S.Connectors>
        <S.CarousalButton onClick={() => setCurrentPage(currentPage === 1 ? currentPage : currentPage - 1)} disabled={currentPage === 1}>
          <KeyboardArrowLeftIcon />
        </S.CarousalButton>
        {currentPage === 1 ? (
          <S.BodySubConainer>
            <img src={metamask} alt="metamask" height={100} />
            <S.BodyContent>
              To join in our affiliate program, we need <br />
              you to connect to your MetaMask wallet.
            </S.BodyContent>
            {!isWalletConnected ? (
              <Button
                onClick={() => {
                  handleWalletConnect(METAMASK);
                }}
                disabled={false}
                customStyle={{ fontSize: "0.875rem", width: "30%", height: "2rem", background: "#F5841F" }}
              >
                {isConnecting ? <ButtonLoader /> : "Connect"}
              </Button>
            ) : (
              <Stack alignItems={"center"} sx={{ color: "#FFFFFF" }}>
                <Typography variant="body2">CONNECTED TO:</Typography>
                <Typography>{getEllipsisString(participant.participantAddress, 10, 9)}</Typography>
              </Stack>
            )}
          </S.BodySubConainer>
        ) : (
          <S.BodySubConainer>
            <img src={WalletConnect} alt="WalletConnect" height={100} />
            <S.BodyContent>
              To join in our affiliate program, we need <br />
              you to connect to your Walletconnect.
            </S.BodyContent>
            {!isWalletConnected ? (
              <Button
                onClick={() => {
                  handleWalletConnect(WALLET_CONNECT);
                }}
                disabled={false}
                customStyle={{ fontSize: "0.875rem", width: "30%", height: "2rem", background: "#F5841F" }}
              >
                {isConnecting ? <ButtonLoader /> : "Connect"}
              </Button>
            ) : (
              <Stack alignItems={"center"} sx={{ color: "#FFFFFF" }}>
                <Typography variant="body2">CONNECTED TO:</Typography>
                <Typography>{getEllipsisString(participant.participantAddress, 10, 9)}</Typography>
              </Stack>
            )}
          </S.BodySubConainer>
        )}
        <S.CarousalButton onClick={() => setCurrentPage(currentPage === 2 ? currentPage : currentPage + 1)} disabled={currentPage === 2}>
          <KeyboardArrowRightIcon />
        </S.CarousalButton>
      </S.Connectors>
      <S.DotContainer>
        <S.Dot currentPage={currentPage === 1} key={`1`} />
        <S.Dot currentPage={currentPage === 2} key={`2`} />
      </S.DotContainer>
      <Button
        onClick={() => {
          if (isWalletConnected) setPage(1);
        }}
        disabled={!isWalletConnected || !participant.participantAddress}
        customStyle={{ fontSize: "14px", width: "112px", height: "32px" }}
      >
        Next
      </Button>
    </S.ConnectPageWrapper>
  );
};

export default ConnectPage;