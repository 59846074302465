import { EthereumProvider } from "@walletconnect/ethereum-provider";
import Web3 from "web3";
import { WALLET_CONNECT } from "../../../utils/constant";
import { lottoContract } from "./web3Contract";

export const SUPPORTED_NETWORKS = [80001, 137];

export const web3 = new Web3(new Web3.providers.HttpProvider(`${process.env.REACT_APP_MATIC_RPC_URL}`));

export const CONTRACT: { [id: number]: string } = {
  80001: `${process.env.REACT_APP_LOTTO_CONTRACT_ADDRESS}`,
  56: `${process.env.REACT_APP_BSC_LOTTO}`,
};

const CURRENT_CHAINID = Number(process.env.REACT_APP_CURRENT_CHAINID);

export const getProvider = async (connector: any) => {
  if (connector === WALLET_CONNECT) {
    const provider = await EthereumProvider.init({
      projectId: `${process.env.REACT_APP_PROJECT_ID}`, // REQUIRED your projectId
      chains: [CURRENT_CHAINID], // REQUIRED chain ids
      showQrModal: true, // REQUIRED set to "true" to use @web3modal/standalone,
      rpcMap: {
        80001: `${process.env.REACT_APP_MATIC_RPC_URL}`,
        56: `${process.env.REACT_APP_BSC_RPC}`,
      },
    });
    web3.setProvider(provider);
    return provider;
  } else {
    if ((window as any)?.ethereum) {
      web3.setProvider((window as any)?.ethereum);
      return (window as any)?.ethereum;
    }
    return null;
  }
};
// const provider = getProvider();
