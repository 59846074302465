import { AsYouType } from "libphonenumber-js";
import { web3 } from "../../services/ethereum/contract/web3";

export const getCurrentChainId = async () => {
  try {
    let currentChainId = await web3.eth.getChainId();
    return currentChainId;
  } catch (e) {
    console.log(e);
    return 80001;
  }
};

export const getWalletBalance = async (account: string) => {
  let walletBalance = await web3.eth.getBalance(account);
  let balEther = parseFloat(web3.utils.fromWei(walletBalance, "ether")).toFixed(4);
  return balEther;
};

export const getEllipsisString = (text: string, start: number, end: number) => {
  if (text.trim().length < start + end) return text;
  const startWith = text.slice(0, start);
  const endWith = text.slice(-end);
  return `${startWith}....${endWith}`;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  let phone = "+" + phoneNumber.replace(/\D+/g, "");
  const asYouType = new AsYouType();

  asYouType.input(phone);
  return asYouType.isValid();
};
