import EarnImg from "../../assets/images/earnImg.svg";
import ReferImg from "../../assets/images/referImg.svg";
import RegisterImg from "../../assets/images/registerImg.svg";

export const FREQUENTLY_ASKED_QUESTIONS = [
  {
    label: "What is the Yearn Affiliate Program ?",
  },
  {
    label: "What requirements do I need to meet to apply?",
  },
  {
    label: "How will I know if my application is accepted?",
  },
  {
    label: "Can I become Yearn Affiliate in any country?",
  },
  {
    label: "How are my referrals tracked?",
  },
  {
    label: "What is the commission structure?",
  },
  {
    label: "How does payment work?",
  },
  {
    label: "What information can I track about my referrals?",
  },
  {
    label: "What are Yearn’s brand guidelines?",
  },
];

export const LEVELS_DATA = [
  {
    header: "L1",
    label: "Level 1",
    content: "Upto 10%",
  },
  {
    header: "L2",
    label: "Level 2",
    content: "Upto 5%",
  },
  {
    header: "L3",
    label: "Level 3",
    content: "Upto 3%",
  },
  {
    header: "L4",
    label: "Level 4",
    content: "Upto 2%",
  },
  {
    header: "L5",
    label: "Level 5",
    content: "Upto 1%",
  },
];

export const HOW_IT_WORKS_DATA = [
  {
    id: 1,
    title: "Register",
    content: "Register with your Metamask ID as a free user, by filling out our simple form with basic information.",
    image: RegisterImg,
  },
  {
    id: 2,
    title: "Refer",
    content: "Refer to anyone using the referral link and promote as much as you can. There is no limit to referrals.",
    image: ReferImg,
  },
  {
    id: 3,
    title: "Earn",
    content: "Earn money and token for every purchase your referral makes. Earn as much as you can refer.",
    image: EarnImg,
  },
];

//Connectors
export const METAMASK = "METAMASK";
export const WALLET_CONNECT = "WALLET_CONNECT";

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";
