import { Dispatch, FC, SetStateAction } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const styles = {
  input: {
    width: "100%",
    height: 45,
    paddingRight: 45,
    borderRadius: 5,
    color: "#FFFFFF",
    fontSize: 18,
    letterSpacing: 1.5,
    WebkitBoxShadow: "none",
    fontWeight: "400",
    display: "",
    borderColor: "rgb(89 89 89 / 50%)",
    background: "transparent",
    border: "1px solid #ffffff4d",
    padding: "1rem 4rem",
  },
  inputError: {
    width: "100%",
    paddingRight: 45,
    height: 45,
    borderRadius: 12,
    color: "#E64437",
    fontSize: 18,
    letterSpacing: 1.5,
    WebkitBoxShadow: "none",
    border: "2px solid #E64437",
  },
  flag: {
    width: "3rem",
    backgroundColor: "white",
  },
  flagError: {
    borderBottom: "2px solid #E64437",
    borderTop: "2px solid #E64437",
    borderLeft: "2px solid #E64437",
    borderRight: "0px",
    borderRadius: 12,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: "white",
  },
};

interface IPhoneProps {
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  error: boolean;
  onBlur: () => void;
  phoneNumber: string;
  onFocus: () => void;
}
const PhoneNumberInput: FC<IPhoneProps> = ({ setPhoneNumber, error, onBlur, phoneNumber, onFocus }) => {
  return (
    <PhoneInput
      onFocus={onFocus}
      inputStyle={error ? styles.inputError : styles.input}
      buttonStyle={error ? styles.flagError : styles.flag}
      country={"us"}
      placeholder="Mobile*"
      enableLongNumbers={true}
      onChange={(phone) => setPhoneNumber(phone)}
      onBlur={onBlur}
      value={phoneNumber}
    />
  );
};

export default PhoneNumberInput;
