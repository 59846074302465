import { Box, styled } from "@mui/material";

export namespace S {
  export const ButtonLoader = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "4.0625rem",
    span: {
      color: "#FFFFFF",
    },
  }));
}
