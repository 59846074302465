import { FC, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import S from "./header.styled";
import HeaderLogo from "../../../assets/images/HeaderLogo.png";
import LearnMore from "../../../assets/images/learnMore.pdf";
import ConnectButton from "../../connect/ConnectButton";

// import PDF from "../../../assets/images/learnMore.pdf";
const Header: FC = () => {
  //state values
  const [toggle, setToggle] = useState(false);

  //functions
  const handleToggle = (value: boolean) => {
    setToggle(value);
  };

  return (
    <S.HeaderContainer>
      <S.HeaderAlignmentContainer>
        <S.HeaderLogo src={HeaderLogo} alt="app logo" />
        <S.MenuContainer isToggleActive={toggle}>
          {toggle ? <CloseIcon onClick={() => handleToggle(false)} /> : <MenuIcon onClick={() => handleToggle(true)} />}
          <S.CustomLink href="#how-it-works" isToggleActive={toggle}>
            How it works
          </S.CustomLink>
          <S.CustomLink href={`${process.env.REACT_APP_YEARN_TOGETHER_DASHBOARD}`} target="_blank" isToggleActive={toggle}>
            Launch App
          </S.CustomLink>
          <S.CustomLink href="https://docs.yearntogether.com/" target="_blank" isToggleActive={toggle}>
            Docs
          </S.CustomLink>
          <S.CustomLink href={LearnMore} download={"YearnTogether-brochure.pdf"} isToggleActive={toggle}>
            Brochure
          </S.CustomLink>
          <ConnectButton content={"Join Affiliate"} />
        </S.MenuContainer>
      </S.HeaderAlignmentContainer>
    </S.HeaderContainer>
  );
};

export default Header;
