import { styled, Box } from "@mui/material";
import Link from "@mui/material/Link/Link";

namespace S {
  export const HeaderContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "80px",
    padding: "0 20px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  }));

  export const HeaderAlignmentContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "1380px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
      padding: "10px 0",
    },
  }));

  export const HeaderLogo = styled("img")(({ theme }) => ({
    height: "1.5rem",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
      height: "0.875rem",
      zIndex: 6,
    },
  }));

  export const MenuIcons = styled("img")(() => ({
    height: "18px",
    width: "18px",
    alignSelf: "center",
  }));

  export const MenuContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "isToggleActive" })(
    ({ isToggleActive }: { isToggleActive: boolean }) =>
      ({ theme }) => ({
        display: "flex",
        gap: "33px",
        alignItems: "center",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
          position: "relative",
          flexDirection: "column",
          alignItems: "flex-end",
          zIndex: 5,
          "&:after": {
            display: isToggleActive ? "block" : "none",
            content: '""',
            position: "absolute",
            top: "-10%",
            right: "-10%",
            width: "100vw",
            height: "60vh",
            background: "linear-gradient(141.69deg, rgba(255, 255, 255, 0.074) 0%, rgba(255, 255, 255, 0.028) 100%)",
            backdropFilter: "blur(30px)",
            zIndex: 1,
            borderRadius: "0.5rem",
          },
        },
        ".MuiSvgIcon-root": {
          display: "none",
          [theme.breakpoints.down("sm")]: {
            display: "block",
            zIndex: 2,
          },
        },
        button: {
          [theme.breakpoints.down("sm")]: {
            fontSize: "0.875rem",
            display: isToggleActive ? "block" : "none",
            zIndex: 2,
          },
        },
      }),
  );

  export const CustomLink = styled(Link, { shouldForwardProp: (prop) => prop !== "isToggleActive" })(
    ({ isToggleActive }: { isToggleActive: boolean }) =>
      ({ theme }) => ({
        textDecoration: "none",
        color: theme.palette.custom.text.white,
        [theme.breakpoints.down("sm")]: {
          display: isToggleActive ? "block" : "none",
          zIndex: 2,
        },
      }),
  );
}

export default S;
