import { FC } from "react";
import S from "./custom-modal.styled";

interface ContainerProps {
  open: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
}

interface HeaderProps {
  children?: React.ReactNode;
  handleClose?: () => void;
}

interface BodyFooterProps {
  children?: React.ReactNode;
}

export const CustomModal: FC<ContainerProps> = ({ children, open, onClose }) => {
  return (
    <S.ModalContainer onClose={onClose} open={open}>
      {children}
    </S.ModalContainer>
  );
};

export const ModalHeader: FC<HeaderProps> = ({ children, handleClose }) => {
  return <S.ModalHeaderContainer>{children}</S.ModalHeaderContainer>;
};

export const ModalBody: FC<BodyFooterProps> = ({ children }) => {
  return <S.ModalBodyContainer>{children}</S.ModalBodyContainer>;
};

export const ModalFooter: FC<BodyFooterProps> = ({ children }) => {
  return <S.ModalFooterContainer>{children}</S.ModalFooterContainer>;
};
