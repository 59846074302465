import { FC } from "react";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/app.css";
import CustomProvider from "../utils/provider";
import { ToastContainer } from "../utils/widgets/toast";
import Layout from "./layout";

const App: FC = () => {
  return (
    <CustomProvider>
      <Layout />
      <ToastContainer newestOnTop hideProgressBar={true} pauseOnFocusLoss={false} />
    </CustomProvider>
  );
};

export default App;
