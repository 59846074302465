import { createTheme, Theme } from "@mui/material";

export const lightTheme: Theme = createTheme({
  palette: {
    mode: "light",
    custom: {
      primary: {
        main: "#1967FC",
      },
      text: {
        primary: "#16151A",
        highlight: "#1967FC",
        grey: "rgb(89 89 89 / 50%)",
        black: "#000000",
        white: "#FFFFFF",
        error: "#FF0000",
      },
      bg: {
        main: "#050016",
        secondary: "#EFFAFD",
        black: "#16151A",
        blue: "#EFFAFD",
      },
      strokes: {
        alpha_grey1: "rgba(62, 62, 62, 0.2)",
        alpha_grey2: "rgba(62, 62, 62, 0.3)",
        lightGrey: "#D9D9D9",
        secondary: "#EAEAEA",
      },
      button: {
        primary_text: "#FFFFFF",
        secondary_text: "#ffffff6b",
        primary_background: "#1967FC",
        secondary_background: "#595959",
        yellow: "#EFB614",
        hover: "#1846dd",
      },
      toast: {
        success: "#1A9035",
        error: "#EC3C93",
        info: "#2196f3",
        warning: "#FDCA17",
      },
    },
  },
});
