import { Dispatch, SetStateAction } from "react";
import { IAffiliateUser, Participant } from "../../utils/types/common";
import Toast from "../../utils/widgets/toast";
import { lottoContract } from "../ethereum/contract/web3Contract";
import { joinAffiliate } from "./../../actions/index";

let CryptoJS = require("crypto-js");

const encryptData = (data: any) => {
  let salt = process.env.REACT_APP_SALT;
  let ciphertext = CryptoJS.AES.encrypt(data, salt).toString();
  return ciphertext;
};

export const registerAffiliateUser = async (
  userInfo: IAffiliateUser,
  participant: Participant,
  connector: string,
  setIsConnecting: Dispatch<SetStateAction<boolean>>,
  setPage: Dispatch<SetStateAction<number>>,
  setParticipant: Dispatch<SetStateAction<Participant>>,
) => {
  try {
    let encryptableUserInfo = JSON.stringify({ ...userInfo, address: participant.participantAddress });
    const encryptedUserInfo = encryptData(encryptableUserInfo);

    const postConfig = {
      method: "POST",
      body: JSON.stringify({
        data: encryptedUserInfo,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const affiliateResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/affiliate`, postConfig);
    if (affiliateResponse.status >= 200 && affiliateResponse.status < 400) {
      const affiliate = await affiliateResponse.json();
      console.log("affiliate", affiliate);
      if (affiliate)
        joinAffiliate(connector, setIsConnecting, setPage, setParticipant, async () => {
          let res = await lottoContract.participantByAddress(participant.participantAddress);
          if (res.id) {
            setParticipant({ ...participant, participantId: res.id });
            setPage(2);
            Toast({ message: "You are successfully registered as an affiliate!", type: "success" });
          }
        });
    } else {
      setIsConnecting(false);
      Toast({
        message: `Oops! Register affiliate user failed with status--> ${affiliateResponse.status},Might be the entered mobile number or mail id already exist.`,
      });
    }
  } catch (e) {
    setIsConnecting(false);
    Toast({ message: "Oops! Register affiliate user failed! try after sometime." });
    console.log("Registering affiliate user failed -->", e);
  }
};