import { FC, useContext } from "react";
import { Step, StepLabel } from "@mui/material";
import S from "./affiliate-modal.styled";
import closeIcon from "../../assets/images/CloseIcon.svg";
import { JoinAffiliateContext } from "../../utils/contexts/JoinAffiliateContext";
import { CustomModal } from "../custom-requirement-modal/CustomModal";
import { ModalHeader } from "../custom-requirement-modal/CustomModal";
import { ModalBody } from "../custom-requirement-modal/CustomModal";
import ConnectPage from "./pages/ConnectPage";
import UserInfoPage from "./pages/UserInfoPage";
import WelcomeToAffiliatePage from "./pages/WelcomeToAffiliatePage";

interface ModalProps {
  open: boolean;
  onClose: () => void;
}
const AffiliateModal: FC<ModalProps> = ({ open, onClose }) => {
  //state values
  const { page } = useContext(JoinAffiliateContext);

  return (
    <>
      <CustomModal onClose={onClose} open={open}>
        <S.AffiliateModalSubContainer>
          <ModalHeader>
            <S.HeadContainer>
              <S.TitleBox>
                <S.ModalTitle variant="h4">
                  Affiliate <span>Requirements</span>
                </S.ModalTitle>
                <S.CloseIcon src={closeIcon} alt="closeIcon" onClick={() => onClose()} />
              </S.TitleBox>
              <S.ModalStepper activeStep={page} nonLinear>
                <Step>
                  <StepLabel>Connect</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Personal Info</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Confirmation</StepLabel>
                </Step>
              </S.ModalStepper>
            </S.HeadContainer>
          </ModalHeader>
          <ModalBody>{page === 0 ? <ConnectPage /> : page === 1 ? <UserInfoPage /> : <WelcomeToAffiliatePage />}</ModalBody>
        </S.AffiliateModalSubContainer>
      </CustomModal>
    </>
  );
};

export default AffiliateModal;
