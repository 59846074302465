import { Dialog, styled, DialogTitle, Stack } from "@mui/material";

namespace S {
  export const ModalContainer = styled(Dialog)(({ theme }) => ({
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    zIndex: "1",

    ".MuiBackdrop-root": {
      background: "none",
    },

    ".MuiPaper-root": {
      maxWidth: "62.5rem",
      minWidth: "35rem",
      width: "50rem",
      height: "55rem",
      background: "linear-gradient(141.69deg, rgba(255, 255, 255, 0.074) 0%, rgba(255, 255, 255, 0.028) 100%)",
      backdropFilter: "blur(60px)",
      borderRadius: "20px",
      border: "2px solid rgba( 255, 255, 255, 0.18 )",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "95%",
        minWidth: "95%",
        padding: "0.5rem 1rem",
      },
    },

    ".MuiDialog-paper": {
      maxWidth: "calc(100vw - 2.5rem)",
      maxHeight: "calc(100vh - 2.5rem)",
      overflowY: "unset",
      [theme.breakpoints.down("md")]: {
        maxWidth: "calc(100vw - 1.875rem)",
      },
      [theme.breakpoints.down("sm")]: {
        maxHeight: "95vh",
      },
    },

    ".MuiTypography-root": {
      padding: 0,
      //   css-1t1j96h-MuiPaper-root-MuiDialog-paper
    },
  }));

  export const ModalHeaderContainer = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: undefined,
    position: "relative",
    borderRadius: "0.625rem 0.625rem 0 0",
    padding: "2rem",
    minHeight: "20%",
    boxSizing: "border-box",
  }));

  export const ModalBodyContainer = styled(Stack)(({ theme }) => ({
    backgroundColor: undefined,
    minHeight: "70%",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "fit-content",
    },
  }));

  export const ModalFooterContainer = styled(Stack)(() => ({
    backgroundColor: undefined,
    borderRadius: "0 0 0.625rem 0.625rem",
    padding: 0,
  }));
}

export default S;
