import { ToastContainer } from "react-toastify";
import { styled } from "@mui/material";
import { lightTheme } from "../../theme";

namespace S {
  export const Toast = styled("div")(() => ({
    fontWeight: "600",
    letterSpacing: "0.5px",
  }));

  export const ToastContainerStyled = styled(ToastContainer)(() => ({
    ".Toastify__toast": {
      color: lightTheme.palette.custom.bg.main,

      ".Toastify__close-button": {
        color: lightTheme.palette.custom.bg.main,
      },

      ".Toastify__toast-icon > svg": {
        fill: lightTheme.palette.custom.bg.main,
      },

      "&--success": {
        backgroundColor: lightTheme.palette.custom.toast.success,
      },
      "&--error": {
        backgroundColor: lightTheme.palette.custom.toast.error,
      },
      "&--warning": {
        backgroundColor: lightTheme.palette.custom.toast.warning,
      },
      "&--info": {
        "&, .Toastify__close-button": {
          backgroundColor: lightTheme.palette.custom.toast.info,
        },

        ".Toastify__toast-icon > svg": {
          fill: lightTheme.palette.custom.bg.main,
        },
      },
      "&, .Toastify__close-button": {
        opacity: "1 !important",
      },
    },
  }));
}

export default S;
