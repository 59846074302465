import { CSSProperties, FC, useContext, useState } from "react";
import S from "./connect-button.styled";
import { JoinAffiliateContext } from "../../utils/contexts/JoinAffiliateContext";
import { Button } from "../../utils/widgets/buttons";
import AffiliateModal from "../join-affiliate-modal/AffiliateModal";

interface IConnectButtonProps {
  content: any;
  customStyle?: CSSProperties;
}

const ConnectButton: FC<IConnectButtonProps> = ({ content, customStyle }) => {
  //state values
  const [requirementModal, setRequirementModal] = useState<HTMLButtonElement | null>(null);
  const { setPage, setIsConnecting, setParticipant, setIsWalletConnected } = useContext(JoinAffiliateContext);

  //functions
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRequirementModal(event.currentTarget);
  };

  const handleClose = () => {
    setRequirementModal(null);
    setPage(0);
    setIsConnecting(false);
    setParticipant({ participantId: "", participantAddress: "" });
    setIsWalletConnected(false);
  };

  return (
    <S.ConnectWrapper>
      <Button onClick={handleClick} disabled={false} customStyle={{ ...customStyle, fontSize: "16px" }}>
        {content}
      </Button>
      {requirementModal && <AffiliateModal onClose={handleClose} open={Boolean(requirementModal)} />}
    </S.ConnectWrapper>
  );
};

export default ConnectButton;
