import { styled, Button } from "@mui/material";

namespace S {
  export const CustomButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "disabled",
  })(({ disabled }: { disabled: boolean }) => ({ theme }) => ({
    fontSize: "1rem",
    cursor: disabled ? "default" : "pointer",
    borderRadius: "0.5rem",
    textTransform: "none",
    fontWeight: 600,
    color: theme.palette.custom.button.primary_text,
    backgroundSize: "200%",
    opacity: 1,
    backgroundPosition: "0%",
    background: disabled ? theme.palette.custom.strokes.alpha_grey1 : theme.palette.custom.button.primary_background,
    padding: "7px 19px",
    "&:hover": {
      background: disabled ? theme.palette.custom.button.secondary_background : theme.palette.custom.button.hover,
    },
  }));
}
export default S;
