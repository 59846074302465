import { FC } from "react";
import S from "./refer-and-yearn.styled";
import BackGroundGrill from "../../assets/images/Grill.svg";
import PlayImg from "../../assets/images/Play.svg";
import BlurredPink1 from "../../assets/images/blurredPink1.svg";
import BlurredPink2 from "../../assets/images/blurredPink2.svg";
import FacebookImg from "../../assets/images/facebook.svg";
import InstagramImg from "../../assets/images/instagram.svg";
import TwitterImg from "../../assets/images/twitter.svg";
import YoutubeImg from "../../assets/images/youtube.svg";
import { HOW_IT_WORKS_DATA } from "../../utils/constant";
import ConnectButton from "../connect/ConnectButton";
import Header from "../layout/header";

const ReferAndYEarn: FC = () => {
  return (
    <S.MainWrapper>
      <S.BlurredImgWrapper>
        <S.BackgroundGrillImg src={BackGroundGrill} alt="background grill" />
        <S.BlurredImg1 src={BlurredPink1} alt="blurred background image" />
        <S.BlurredImg2 src={BlurredPink2} alt="blurred background image" />
        <S.ReferAndYearnContainer>
          <S.YoutubeIcon src={YoutubeImg} alt="youtube logo" />
          <S.InstagramIcon src={InstagramImg}  alt="instagram logo"/>
          <S.TwitterIcon src={TwitterImg} alt='twitter logo' />
          <S.FacebookIcon src={FacebookImg} alt='facebook logo'/>
          <Header />
          <S.ReferAndYearnTopContainer>
            <S.AffiliateProgram>
              <S.HeaderText className="sub-title">
                We are not just <S.HighlightText>building</S.HighlightText> a platform;
              </S.HeaderText>
              <S.HeaderText className="main-title">
                We are building a <S.HighlightText className="yellow-text">community.</S.HighlightText>
              </S.HeaderText>
              <S.SubText>
                Earn money and crypto tokens with every referral you make! Our affiliate program is a great way to earn extra income while sharing
                your favorite products or services with friends and family. Sign up now and start earning today!
              </S.SubText>
              <S.ButtonContainer>
                <ConnectButton
                  content={
                    <>
                      Join As Affiliate <S.CustomChevronRightIcon />
                    </>
                  }
                  customStyle={{ padding: "16px 60px", fontSize: "16px" }}
                />
                <S.PlayButton
                  onClick={() => {
                    window.open("https://www.youtube.com/watch?v=iubKwVTMmQo", "_blank");
                  }}
                >
                  <S.ButtonImage src={PlayImg} alt="play-icon" />
                </S.PlayButton>
              </S.ButtonContainer>
            </S.AffiliateProgram>
          </S.ReferAndYearnTopContainer>
        </S.ReferAndYearnContainer>
        <S.HowItWorksContainer id="how-it-works">
          <S.HowItWorksHeader>How it works ?</S.HowItWorksHeader>
          <S.HowItWorksSubHeader sx={{ marginTop: "1rem" }}>
            Like any other affiliate program we follow easy three step process. Being affiliate is one of the simplest way to earn tokens and rewards
            without giving
            <br /> anything in return. All you have to do is promote as much as you can.
          </S.HowItWorksSubHeader>
          <S.CardsContainer>
            {HOW_IT_WORKS_DATA.map((data: any, index: any) => {
              return (
                <S.Card key={data?.id} sx={{ ...(index === HOW_IT_WORKS_DATA.length - 1 && { gridColumnEnd: "-1", justifySelf: "center" }) }}>
                  <S.HowItWorksImg src={data?.image} alt="how it works image" />
                  <S.HowItWorksTitle>{data.title}</S.HowItWorksTitle>
                  <S.HowItWorksContent>{data.content}</S.HowItWorksContent>
                </S.Card>
              );
            })}
          </S.CardsContainer>
        </S.HowItWorksContainer>
      </S.BlurredImgWrapper>
    </S.MainWrapper>
  );
};

export default ReferAndYEarn;
