import { FC } from "react";
import S from "./button.styled";
import { customButtonType } from "../../types/button";

export const Button: FC<customButtonType> = ({ onClick, children, customStyle, disabled = false }) => {
  return (
    <S.CustomButton onClick={onClick} sx={customStyle} disabled={disabled}>
      {children}
    </S.CustomButton>
  );
};
