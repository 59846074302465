import { FC, useState } from "react";
import { InputAdornment, Tooltip } from "@mui/material";
import S from "./input-with-copy-and-share.styled";
import { Button } from "../buttons";

type InputWithCopyAndShareProps = {
  url: string;
};

const InputWithCopyAndShare: FC<InputWithCopyAndShareProps> = ({ url }) => {
  //state values
  const [toolTipTime, setToolTipTime] = useState(false);

  //functions
  const handleCopy = () => {
    if (url.trim().length) {
      setToolTipTime(true);
      navigator.clipboard.writeText(url);
      setTimeout(() => {
        setToolTipTime(false);
      }, 2000);
    }
  };

  return (
    <S.InputContainer>
      <Tooltip title={toolTipTime ? "copied to clipboard" : "copy to clipboard"} arrow>
        <S.InputWithIcon
          readOnly
          value={url}
          onClick={handleCopy}
          disableUnderline
          endAdornment={
            <InputAdornment position="end">
              <Button
                onClick={handleCopy}
                customStyle={{
                  height: "2.25rem",
                  width: "1rem",
                  minWidth: "1rem",
                  borderRadius: "0 0.5rem 0.5rem 0",
                }}
              >
                <S.CustomContentCopyTwoToneIcon />
              </Button>
            </InputAdornment>
          }
        />
      </Tooltip>
      <Button onClick={() => {}} customStyle={{ width: "1rem", height: "2.25rem", minWidth: "1rem" }}>
        <S.CustomReplyIcon />
      </Button>
    </S.InputContainer>
  );
};

export default InputWithCopyAndShare;
