import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import ReplyIcon from "@mui/icons-material/Reply";
import { styled, Input, Box } from "@mui/material";

namespace S {
  export const InputContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  }));

  export const InputWithIcon = styled(Input)(({ theme }) => ({
    // background: theme.palette.custom.strokes.primary,
    borderRadius: "0.5rem",
    minHeight: "2.2rem",
    width: "23.4375rem",
    paddingLeft: "1rem",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    color: theme.palette.custom.text.white,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    input: {
      cursor: "pointer",
      width: "100%",
      fontSize: "0.8rem",
    },
  }));

  export const CustomContentCopyTwoToneIcon = styled(ContentCopyTwoToneIcon)(() => ({
    fontSize: "1.25rem",
  }));

  export const CustomReplyIcon = styled(ReplyIcon)(() => ({
    transform: "scaleX(-1)",
    fontSize: "1.5rem",
  }));
}

export default S;
