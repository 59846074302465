import { Box, styled } from "@mui/material";

namespace S {
  export const ContentContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    overflowY: "auto",
    background: theme.palette.custom.bg.main,
  }));
}

export default S;
