import { Checkbox, FormControlLabel, FormGroup, IconButton, Stack } from "@mui/material";
import { Typography, styled, Box, Stepper, TextField } from "@mui/material";

namespace S {
  export const HeadContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "6rem",
  }));

  export const AffiliateModalSubContainer = styled(Box)(({ theme }) => ({
    padding: "2rem",
    overflow: "auto",
    margin: "0.8rem 0",
    boxSizing: "border-box",
    height: "100%",
  }));

  export const ModalTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.text.highlight,
    fontSize: "1.5rem",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "2.25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "clamp(14px,16px,18px)",
    },
    span: {
      color: theme.palette.custom.text.white,
    },
  }));

  export const TitleBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "0.5rem 0",
    },
  }));

  export const CloseIcon = styled("img")(({ theme }) => ({
    cursor: "pointer",
  }));
  export const ModalStepper = styled(Stepper)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      gap: "0.75rem",
      justifyContent: "center",
    },
    ".MuiStepConnector-root": {
      display: "none",
    },
    ".MuiStepLabel-root": {
      gap: "0.5rem",
      [theme.breakpoints.down("sm")]: {
        whiteSpace: "nowrap",
      },
    },
    ".MuiStepLabel-label": {
      color: "#ffffff85!important",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontWeight: 500,
      fontFamily: "Poppins !important",
    },
    ".MuiStepLabel-label.Mui-active": {
      color: `${theme.palette.custom.text.white} !important`,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontWeight: 500,
      fontFamily: "Poppins !important",
    },
    ".MuiStep-root": {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
        flex: 0.5,
      },
    },
  }));

  export const BodyTitle = styled(Typography)(({ theme }) => ({
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "2.25rem",
    color: theme.palette.custom.text.white,
    [theme.breakpoints.down("sm")]: {
      fontSize: "clamp(14px,16px,18px)",
      textAlign: "center !important",
    },
  }));

  export const Connectors = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  }));

  export const CarousalButton = styled(IconButton)(({ theme, disabled }) => ({
    height: "1rem",
    width: "1rem",
    svg: {
      height: "7rem",
      width: "5rem",
      color: disabled ? theme.palette.custom.strokes.alpha_grey1 : theme.palette.custom.button.primary_background,
    },
  }));

  export const BodyContent = styled(Typography)(({ theme }) => ({
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    textAlign: "center",
    color: theme.palette.custom.text.white,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center !important",
    },
  }));

  export const ConnectPageWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
    padding: "2rem !important",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0 !important",
      gap: "0.5rem",
    },
  }));

  export const BodySubConainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "4rem",
    border: `1px solid ${theme.palette.custom.strokes.lightGrey}`,
    borderRadius: "0.625rem",
    padding: "2.5rem 1.5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2% 1%",
      button: {
        minWidth: "100px",
      },
    },
  }));

  export const PersonalConainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "3rem",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }));

  export const InformationConainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    width: "100%",
    padding: "0 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2%",
    },
  }));

  export const CustomTextField = styled(TextField)(({ theme }) => ({
    input: { color: theme.palette.custom.text.white },
    "& label.Mui-focused": {
      color: theme.palette.custom.text.white,
    },
    "& MuiInputBase-input": {
      color: "red",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        opacity: "0.3",
      },
      "&:hover fieldset": {
        borderColor: "white",
        opacity: "0.7",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
        opacity: "0.7",
      },
    },
  }));

  export const ImageConainer = styled(Box)(({ theme }) => ({}));

  export const PersonalTitle = styled(Typography)(({ theme }) => ({
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
    color: theme.palette.custom.text.primary,
  }));

  export const CheckBoxLabel = styled(FormControlLabel)(({ theme }) => ({
    paddingBottom: "0.5rem",
    width: "100%",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0.5rem",
    },
    span: {
      color: theme.palette.custom.text.white,
    },
    p: {
      fontSize: "0.55rem",
      textAlign: "left",
    },
  }));

  export const CustomFormGroup = styled(FormGroup)(({ theme }) => ({
    width: "100%",
    padding: "0 5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0 1rem",
    },
  }));

  export const CustomCheckBox = styled(Checkbox)(({ theme, checked }) => ({
    color: checked ? "#0AAD2E" : theme.palette.custom.text.grey,
  }));

  export const UserInfoWrapper = styled(Box)(({ theme }) => ({
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0",
      overflow: "auto",
      height: "75%",
    },
  }));

  export const ShareInput = styled(TextField)(({ theme }) => ({}));

  export const WelcomeText = styled(Typography)(({ theme }) => ({
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "1.375rem",
    lineHeight: "2.0625rem",
    color: theme.palette.custom.text.white,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      textAlign: "center",
    },
    span: {
      color: theme.palette.custom.text.highlight,
    },
  }));

  export const WelcomeContent = styled(Typography)(({ theme }) => ({
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    textAlign: "center",
    paddingTop: theme.spacing(2),
    color: theme.palette.custom.text.white,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  }));

  export const ShareContent = styled(Typography)(({ theme }) => ({
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#EFB614",
    paddingTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "left",
    },
  }));

  export const WelcomeContainer = styled(Box)(({ theme }) => ({
    padding: "2rem",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    height: "38rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0",
      justifyContent: "flex-start",
      img: {
        width: "60px",
        height: "auto",
      },
      button: {
        width: "fit-content !important",
      },
    },
  }));

  export const ShareBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "2rem",
    paddingBottom: "1.5rem",
  }));

  export const DotContainer = styled(Stack)(({ theme }) => ({
    width: "50%",
    maxWidth: "50%",
    gap: theme.spacing(1.1),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    p: {
      fontWeight: "bold",
    },
  }));

  export const Dot = styled(Box, {
    shouldForwardProp: (prop) => prop !== "currentPage",
  })(({ currentPage }: { currentPage: boolean }) => ({ theme }) => ({
    width: currentPage ? "1.875rem" : "0.5rem",
    height: "0.5rem",
    borderRadius: currentPage ? "0.375rem" : "50%",
    transitionDuration: "0.2s",
    backgroundColor: currentPage ? theme.palette.custom.primary.main : theme.palette.custom.strokes.alpha_grey1,
  }));
}
export default S;
