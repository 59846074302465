import { FC, ReactNode, createContext, useState, Dispatch, SetStateAction } from "react";
import { Participant } from "../types/common";

type joinAffiliateType = {
  isConnecting: boolean;
  setIsConnecting: Dispatch<SetStateAction<boolean>>;
  isModalLoading: boolean;
  setIsModalLoading: Dispatch<SetStateAction<boolean>>;
  participant: Participant;
  setParticipant: Dispatch<SetStateAction<Participant>>;
  isWalletConnected: boolean;
  setIsWalletConnected: Dispatch<SetStateAction<boolean>>;
  connector: string;
  setConnector: Dispatch<SetStateAction<string>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
};

type JoinAffiliateContextProps = {
  children: ReactNode;
};

const JoinAffiliateContext = createContext<joinAffiliateType>({
  isConnecting: false,
  setIsConnecting: () => {},
  isModalLoading: false,
  setIsModalLoading: () => {},
  participant: { participantId: "", participantAddress: "" },
  setParticipant: () => {},
  isWalletConnected: false,
  setIsWalletConnected: () => {},
  connector: "",
  setConnector: () => {},
  page: 0,
  setPage: () => {},
});

const JoinAffiliateContextProvider: FC<JoinAffiliateContextProps> = ({ children }) => {
  //state values
  const [isConnecting, setIsConnecting] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [participant, setParticipant] = useState({ participantId: "", participantAddress: "" });
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [connector, setConnector] = useState("");
  const [page, setPage] = useState(0);

  return (
    <JoinAffiliateContext.Provider
      value={{
        isConnecting,
        setIsConnecting,
        isModalLoading,
        setIsModalLoading,
        participant,
        setParticipant,
        isWalletConnected,
        setIsWalletConnected,
        connector,
        setConnector,
        page,
        setPage,
      }}
    >
      {children}
    </JoinAffiliateContext.Provider>
  );
};

export { JoinAffiliateContext, JoinAffiliateContextProvider };
