import { styled, Box } from "@mui/material";

namespace S {
  export const Layout = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.custom.bg.main,
    minHeight: "100vh",
    maxHeight: "100vh",
    width: "100%",
  }));
}

export default S;
