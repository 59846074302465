import { FC, useContext } from "react";
import S from "../affiliate-modal.styled";
import CheckIcon from "../../../assets/images/CheckIcon.svg";
import { JoinAffiliateContext } from "../../../utils/contexts/JoinAffiliateContext";
import { Button } from "../../../utils/widgets/buttons";
import InputWithCopyAndShare from "../../../utils/widgets/input-with-copy-and-share";

const WelcomeToAffiliatePage: FC = () => {
  //state values
  const { participant } = useContext(JoinAffiliateContext);

  return (
    <S.WelcomeContainer>
      <img src={CheckIcon} alt="CheckIcon" draggable={false} height={184} width={184} />
      <S.WelcomeText>
        Welcome to <span>Yearn Affiliate</span> Program
      </S.WelcomeText>
      <S.WelcomeContent>
        Start referring and earn rewards and tokens as people join through your referral
        <br /> link.Use the same metamask ID used to register in our Yearn dashboard to unlock
        <br /> all your affiliate benefits and start referring.
      </S.WelcomeContent>
      <S.ShareContent>Share this unique URL with your friends:</S.ShareContent>
      <InputWithCopyAndShare
        url={`${process.env.REACT_APP_YEARN_TOGETHER_DASHBOARD}/myreferral/${participant.participantId}/${participant.participantAddress}`}
      />
      <Button
        onClick={() =>
          window.open(`${process.env.REACT_APP_YEARN_TOGETHER_DASHBOARD}/myreferral/${participant.participantId}/${participant.participantAddress}`)
        }
        disabled={false}
        customStyle={{ fontSize: "14px", width: "30%", height: "44px", marginTop: "1rem" }}
      >
        Go to Dashboard
      </Button>
    </S.WelcomeContainer>
  );
};

export default WelcomeToAffiliatePage;
